import React from 'react'
import Modal from "@mui/material/Modal";
import { IoIosClose } from "react-icons/io";
import Box from "@mui/material/Box";
import { FaRegCirclePlay } from "react-icons/fa6";
import "../../Style/Videomdoel.css"
import video from "../../video/videoplayback2.mp4"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "1px solid #e8d7b4",
  boxShadow: 20,
  p: 2,
  borderRaius: 5
};
const Videomodel = ({ show, setShow }) => {

  const handleClose = () => setShow(false);
  // const [play, setPlay] = useState(false);

  return (
    <div>
      <Modal open={show} onClose={handleClose}>
        <Box sx={style} className="">
          <div className='d-flex justify-content-between align-items-center'>
            <h5>Video</h5>
            <IoIosClose onClick={handleClose} size={35} style={{ cursor: "pointer" }} />
          </div>
          <div>
            <iframe loading="lazy" className='videomodel' allow="autoplay" src={video} frameborder="0"></iframe>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default Videomodel
