import React from "react";
import "../../Style/OurSupport.css";
import dh from "../../Images/HomePageImages/DH.png";
import { useNavigate } from "react-router-dom";
import support1 from '../../Images/HomePageImages/1.jpg'
import support2 from '../../Images/HomePageImages/2.jpg'
import support3 from '../../Images/HomePageImages/3.jpg'
import support4 from '../../Images/HomePageImages/4.png'
import support5 from '../../Images/HomePageImages/5.png'

function OurSupport() {
  const navigate = useNavigate();
  return (
    <div className="ourSupport">
      <div className="container">
        <h3 className="text-center">Who We Support</h3>
        <div className="logo-container row">

          {/* <div className="col-lg-3 mt-2 mt-lg-0">
            <div className="logo-item">
              <img src={dh} alt="Brand 1" />
            </div>
          </div> */}

          {/* <div className="col-lg-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <img src={dh} alt="Brand 1" />
            </div>
          </div> */}

          <div className="col-xxl-3 col-lg-6 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://mnmllife.co/pages/the-science"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={support2} alt="Brand 1" />
              </a>
            </div>
          </div>

          <div className="col-xxl-3 col-lg-6 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.georgecapital.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={support1} alt="Brand 1" />
              </a>
            </div>
          </div>

          <div className="col-xxl-3 col-lg-6 mt-2 mt-xxl-0">
            <div className="logo-item">
              <a
                href="https://marqueskinsolutions.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={support3} alt="Brand 1" />
              </a>
            </div>
          </div>

          <div className="col-xxl-3 col-lg-6 mt-2 mt-xxl-0">
            <div className="logo-item">
              <a
                href="https://leifproducts.com/?gad_source=1&gclid=Cj0KCQjwq_G1BhCSARIsACc7Nxot2BDmaGCpbOVYk_9SCDhC_u6GItUqYtgNWI5lD7CupGvGLNFdg8AaAoZQEALw_wcB"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={support4} alt="Brand 1" />
              </a>
            </div>
          </div>

          <div className="col-xxl-3 col-lg-6 mt-2 mt-xxl-4 mt-2">
            <div className="logo-item">
              <a
                href="https://www.funguofelicitykenya.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={support5} alt="Brand 1" />
              </a>
            </div>
          </div>

          {/* <div className="col-lg-3 mt-2 mt-lg-0">
            <div className="logo-item">
              <img src={dh} alt="Brand 1" />
            </div>
          </div> */}

          {/* <div className="col-lg-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <img src={dh} alt="Brand 1" />
            </div>
          </div> */}



          {/* <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div>
          <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div>
          <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div>
          <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div>
          <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OurSupport;
